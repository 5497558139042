import { mapActions, mapState } from 'vuex'

export default {
  name: "Home",
  data () {
    return {
      charts: [],
      showMoreAttributions: false,
      showMoreTimeTable: false,
      showMoreAmbiences: false,
      showMoreBalance: false,
      showMoreWorkLoadAvg: false,
    }
  },
  methods: {
    ...mapActions('role', ['ActionFindRoleCharts']),
    getChartsUser() {
      const params = {
        column_order: 'order'
      }

      this.ActionFindRoleCharts(params)
        .then((r) => {
          this.charts = r.data.filter(o => o.active);
        })
    }
  },
  computed: {
    ...mapState('auth', ['user', 'acesso']),
    
    showMoreButton(){
      return this.isAnima();
    }
    
  },
  created() {
    this.getChartsUser()
  }
}